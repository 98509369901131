import styled from 'styled-components';
import popupbird from '../assets/icons/popupbird.svg';

export const PopUpWrapper = styled.div`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 5;
    
    .referalPopup {
        position: relative;
        
        &:after {
            content: ' ';
            position: absolute;
            display: block;
            width: 137px;
            height: 110px;
            bottom: 0;
            right: 0;
            background: url("${popupbird}");
        }
    }
`;

export const PopUpWindow = styled.div`
    display: block;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    width: ${({ width }) => width ? width : '704px'};
    height: ${({ height }) => height ? height : '150px'};
    transform: translate(-50%, -50%);
    z-index: 5;

    @media only screen and (max-width: 420px) {
        width: ${({ mobileWidth }) => mobileWidth ? mobileWidth : '334px' };
        height: ${({ height }) => height ? height : 'auto'};
        overflow: scroll;
        padding: ${({ mobilePadding }) => mobilePadding || '39px 0'};
    }
`;

export const SmallPopupWrapper = styled.div`
    display: block;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 6px 19px;
    transform: translate(-50%, -50%);
    z-index: 5;
`;
